@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        font-family: 'Bricolage Grotesque', sans-serif;
    }
}

/* Modern scrollbar */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Global styles */
body {
    @apply bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-100;
}

/* Smooth transitions */
.transition-theme {
    @apply transition-all duration-200;
}

/* Container styles */
.container-custom {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
}

/* Card styles */
.card {
    @apply bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 transition-all duration-200 hover:shadow-xl;
}

/* Button styles */
.btn {
    @apply px-4 py-2 rounded-lg font-medium transition-all duration-200;
}

.btn-primary {
    @apply bg-blue-600 hover:bg-blue-700 text-white;
}

.btn-secondary {
    @apply bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 text-gray-900 dark:text-white;
}

/* Section styles */
.section {
    @apply py-16 sm:py-24;
}

/* Heading styles */
.heading-1 {
    @apply text-4xl sm:text-5xl font-bold;
}

.heading-2 {
    @apply text-3xl sm:text-4xl font-bold;
}

.heading-3 {
    @apply text-2xl sm:text-3xl font-bold;
}

/* Grid layouts */
.grid-auto-fit {
    @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6;
}

/* Animation classes */
.hover-lift {
    @apply transition-transform duration-200 hover:-translate-y-1;
}

/* Glass effect */
.glass {
    @apply bg-white/80 dark:bg-gray-800/80 backdrop-blur-md;
}

/* Better mobile tap highlights */
* {
    -webkit-tap-highlight-color: transparent;
}

/* Smooth scrolling */
html {
    scroll-behavior: smooth;
}